<template>
  <div class="container">
    <div class="row text-center my-5" v-if="loading">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col">
          <button class="btn btn-primary float-right mb-2" v-if="!showAdd" @click="showAdd=true">
            <i class="fad fa-plus mr-1"></i>
            Add Allergy or Intolerance
          </button>
        </div>
      </div>
      <div v-if="showAdd">
        <form @submit.prevent="createAllergy">
          <div class="card bg-light">
            <div class="card-body">
              <label>Type</label>
              <select class="form-control mb-3" v-model="form.type" required>
                <option value="allergy">Allergy</option>
                <option value="intolerance">Intolerance</option>
              </select>

              <label>Name</label>
              <input type="text"
                     class="form-control mb-3"
                     v-model="form.name"
                     :placeholder="'Enter a name for the ' + form.type + '...'"
                     required
              />

              <label>Description</label>
              <textarea class="form-control mb-3"
                        rows=4
                        placeholder="Enter a description (optional)..."
                        v-model="form.description"></textarea>

              <button class="btn btn-primary float-right" type="submit">
                <i class="fad fa-plus mr-1"></i>
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
      <div v-else>
        <div class="row mb-3" v-if="allergies.length === 0">
          <div class="col mx-auto">
            <div class="card border-0 shadow-sm">
              <div class="card-body py-4 text-center">
                <i class="fad fa-info-circle fa-5x text-primary mb-2"></i>
                <h3>No allergies or intolerances on record.</h3>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row mb-3">
          <div class="col mx-auto">
            <div v-for="allergy in allergies">
              <form @submit.prevent="updateAllergy">
                <div class="card shadow-sm mb-3">
                  <div class="card-body text-capitalize">
                    <div v-if="editAllergy !== allergy">
                      {{allergy.type}} - {{allergy.name}}
                      <hr class="my-2"/>
                      {{allergy.description}}
                    </div>
                    <div v-else>
                      <label>Type</label>
                      <select class="form-control mb-3" v-model="allergy.type" required>
                        <option value="allergy">Allergy</option>
                        <option value="intolerance">Intolerance</option>
                      </select>

                      <label>Name</label>
                      <input type="text"
                             class="form-control mb-3"
                             v-model="allergy.name"
                             :placeholder="'Enter a name for the ' + allergy.type + '...'"
                             required
                      />

                      <label>Description</label>
                      <textarea class="form-control"
                                rows=6
                                placeholder="Enter a description (optional)..."
                                v-model="allergy.description"></textarea>

                    </div>
                  </div>
                  <div class="card-footer text-right">
                    <div v-if="editAllergy === allergy">
                      <button class="btn btn-light mr-2" @click.prevent="cancelEdit">
                        <i class="fad fa-times mr-1"></i>
                        Cancel
                      </button>
                      <button class="btn btn-primary" type="submit">
                        <i class="fad fa-saves mr-1"></i>
                        Save
                      </button>
                    </div>
                    <div v-else-if="!editAllergy">
                      <button class="btn btn-light mr-2" @click.prevent="editAllergy=allergy">
                        <i class="fad fa-edit mr-1"></i>
                        Edit
                      </button>
                      <button class="btn btn-outline-danger" @click.prevent="deleteAllergy(allergy)">
                        <i class="fad fa-trash mr-1"></i>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      loading: true,
      allergies: [],
      showAdd: false,
      editAllergy: null,
      form: {
        type: "allergy",
        name: "",
        description: "",
        added_by: "client",
        client_id: this.$store.user.id
      }
    };
  },
  computed: {
    user() {
      return this.$store.user;
    }
  },
  methods: {
    fetchAllergies() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/client/allergies/api").then(({ data }) => {
        this.allergies = data;
        this.loading = false;
      });
    },
    createAllergy()
    {
      this.$axios.post(process.env.VUE_APP_API_URL + "/client/allergies/api", this.form).then(({ data }) => {
        this.$EventBus.$emit("alert", data);
        this.fetchAllergies();
        this.showAdd = false;
      });
    },
    updateAllergy()
    {
      this.$axios.post(process.env.VUE_APP_API_URL + "/client/allergies/api/" + this.editAllergy.id, this.editAllergy).then(({ data }) => {
        this.$EventBus.$emit("alert", data);
        this.fetchAllergies();
        this.editAllergy = null;
      });
    },
    deleteAllergy(allergy)
    {
      var confirmed = confirm('Are you sure you wish to delete this record? This is not reversible.');
      if (confirmed){
        this.$axios.delete(process.env.VUE_APP_API_URL + "/client/allergies/api/" + allergy.id).then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchAllergies();
        });
      }
    },
    cancelEdit()
    {
      this.fetchAllergies();
      this.editAllergy = null;
    }
  },
  mounted() {
    this.fetchAllergies();
  }
};
</script>

<style>
</style>
